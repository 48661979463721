[data-theme="dark"] {
  --font-color: #eee;
  --background-color: #333;
}

[data-theme="dark"] body,
.card {
  background-color: var(--background-color);
  color: var(--font-color);
}

[data-theme="dark"] .tech-link {
  color: black;
}

[data-theme="dark"] .card {
  border-color: var(--font-color);
}

[data-theme="dark"] #headshot {
background-image: url("/images/picture-frame-dark.png"), url("/images/headshot-transparent.png");
}